$container-max-widths: (sm: 540px,
md: 720px,
lg: 960px,
xl: 1140px) !default;

$grid-breakpoints: (xs: 0,
sm: 576px,
md: 768px,
lg: 992px,
xl: 1200px) !default;


$theme-colors: (
    "primary": rgb(51, 139, 255),
    "secondary": #898989,
    "light": #fafafa,
    "dark": #0f141a,
    "grey": #374252) !default;
    
$very-light: #969ca6;

$spacer: 1rem;
$enable-rounded: true;

$border-width: 1px !default;
$border-color: rgba(map-get($theme-colors, dark), 0.1) !default;

$border-radius: 7px !default;
$border-radius-lg: 7px !default;
$border-radius-sm: 7px !default;

@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i,800,800i');
$font-family-sans-serif: 'Open Sans',
sans-serif;
$font-family-serif: 'Open Sans',
serif;

$font-size-base: 1rem !default; 
$font-size-lg: ($font-size-base * 1.25) !default;
$font-size-sm: ($font-size-base * 0.875) !default;

$font-weight-light: 300 !default;
$font-weight-normal: 400 !default;
$font-weight-bold: 700 !default;

$font-weight-base: $font-weight-normal !default;
$line-height-base: 1.5 !default;

$h1-font-size: $font-size-base * 3 !default;
$h2-font-size: $font-size-base * 2.25 !default;
$h3-font-size: $font-size-base * 1.875 !default;
$h4-font-size: $font-size-base * 1.625 !default;
$h5-font-size: $font-size-base * 1.25 !default;
$h6-font-size: $font-size-base * 1 !default;

$headings-margin-bottom: 1rem !default;
$headings-font-family: $font-family-serif !default;
$headings-font-weight: 300 !default;
$headings-line-height: 1.2 !default;
$headings-color: inherit !default;

$display1-size: 6rem !default;
$display2-size: 5.5rem !default;
$display3-size: 4.5rem !default;
$display4-size: 3.75rem !default;

$display1-weight: 300 !default;
$display2-weight: 300 !default;
$display3-weight: 300 !default;
$display4-weight: 300 !default;
$display-font-family: $font-family-serif !default;
$display-line-height: 1.1 !default;

$lead-font-size: ($font-size-base * 1.5) !default;
$lead-font-weight: 300 !default;
$lead-line-height: 1.5 !default;

$small-font-size: 0.75rem !default;
$small-2-font-size: 0.9rem !default;
$small-line-height: 1.4 !default;

$blockquote-font-size: ($font-size-base * 1.25) !default;

$hr-border-color: rgba(map-get($theme-colors, dark), 0.1) !default;
$hr-border-width: 1px !default;

$input-placeholder-color: #b7bbc0;
$input-border-color: $border-color;
$input-padding-y: 0.5rem;
$input-padding-x: 0.75rem;
$input-box-shadow: none;

$card-spacer-x: 1.875rem;

$body-color: #000;

$shadow-0: none;
$shadow-1: 0px 5px 25px 0px rgba(0, 0, 0, 0.1);
$shadow-2: 0px 13px 40px rgba(0, 0, 0, 0.2);

$drop-down-color: map-get($theme-colors, dark);
$drop-down-background: #fff;
$drop-down-hover-color: map-get($theme-colors, dark);
$drop-down-hover-background: rgba(map-get($theme-colors, dark), 0.1);
$drop-down-info-font-weight: 600;

@import "bootstrap/bootstrap.scss";
@import "bootstrap/bootstrap-grid.scss";
@import "bootstrap/bootstrap-reboot.scss";
@import "bootstrap/style-specific.scss";